import {Injectable} from '@angular/core';
import * as API from '@aws-amplify/api';

import {
  APIResponse,
  Application, ApplicationSegment, ApplicationSegmentInput, ConfigurationVariable, ConfigurationVariableInput,
  EmptySuccessResponse, Experiment, ExperimentInput, GetExperimentResponse,
  PaginatedList, PatchExperimentRequest, ResolveExperimentRequest, TournamentDefinition,
} from '../../../wildcard-dashboard-common/src/models/wildcloud';
import {AuthorizationService} from '../authorization.service';
import {Utilities} from '../../../wildcard-dashboard-common/src/models/utilities';

export const ENVIRONMENT_PROD = 'live';
export const ENVIRONMENT_DEV = 'dev';

export type WILDCLOUD_ENV = 'live' | 'dev';

function validateEnvironment(env: string) {
  return [ENVIRONMENT_DEV, ENVIRONMENT_PROD].indexOf(env) >= 0;
}

export class WildCloudError extends Error {
  wildCloudError: any;

  constructor(error: any) {

    let message = error.errorMessage;

    if (error.internalError) {
      message = `${message}: ${error.internalError.message}`;
    }

    super(message);

    this.name = error.type;

    console.log(JSON.stringify(error));
    this.wildCloudError = error;
  }

  toString(): string {
    return JSON.stringify(this.wildCloudError);
  }
}

@Injectable({
  providedIn: 'root'
})
export class WildCloudService {
  constructor(
    private authorizationService: AuthorizationService,
  ) {}

  /********************************************
   *
   * Applications
   *
   ********************************************/

  listApplications(paginationToken?: string): Promise<PaginatedList<Application>> {
    const path = `/applications`;
    return this.sendRequest<PaginatedList<Application>>(ENVIRONMENT_PROD, path, 'get', {
      queryStringParameters: {paginationToken}
    });
  }

  getApplication(env: string, applicationId: string): Promise<Application> {
    const path = `/application/${applicationId}`;
    return this.sendRequest(env, path, 'get');
  }

  updateApplication(env: WILDCLOUD_ENV, applicationId: string, patch: Partial<Application>): Promise<Application> {
    const path = `/application/${applicationId}`;
    return this.sendRequest(env, path, 'patch', {
      body: patch,
      queryStringParameters: this.baseEditingQueryStringParams
    });
  }

  async createApplication(app: Omit<Application, 'id'>) {
    const path = `/applications`;

    app.id = Utilities.createGUID();
    const options = {body: app, queryStringParameters: this.baseEditingQueryStringParams};

    await Promise.all([
      this.sendRequest(ENVIRONMENT_DEV, path, 'post', options),
      this.sendRequest(ENVIRONMENT_PROD, path, 'post', options),
    ]);
  }

  async cloneApplication(app: Application) {
    const path = `/applications/${app.id}/clone`;

    app.id = Utilities.createGUID();
    const options = {body: app, queryStringParameters: this.baseEditingQueryStringParams};

    await Promise.all([
      this.sendRequest(ENVIRONMENT_DEV, path, 'post', options),
      this.sendRequest(ENVIRONMENT_PROD, path, 'post', options),
    ]);
  }

  async deleteApplication(applicationId: string, recurse: boolean = false) {
    const path = `/application/${applicationId}`;
    const queryStringParameters = this.baseEditingQueryStringParams;
    if (recurse) { queryStringParameters.recurse = '1'; }

    await Promise.all([
      this.sendRequest(ENVIRONMENT_DEV, path, 'delete', {queryStringParameters}),
      this.sendRequest(ENVIRONMENT_PROD, path, 'delete', {queryStringParameters}),
    ]);
  }

  /********************************************
   *
   * Variables
   *
   ********************************************/

  listVariables(env: string, applicationId: string, paginationToken?: string): Promise<PaginatedList<ConfigurationVariable>> {
    const path = `/application/${applicationId}/variables`;
    return this.sendRequest<PaginatedList<ConfigurationVariable>>(env, path, 'get', {
      queryStringParameters: {paginationToken}
    });
  }

  listVariableVersions(env: string, applicationId: string, name: string, paginationToken?: string): Promise<PaginatedList<ConfigurationVariable>> {
    const path = `/application/${applicationId}/variable/${name}/versions`;
    return this.sendRequest<PaginatedList<ConfigurationVariable>>(env, path, 'get', {
      queryStringParameters: {paginationToken}
    });
  }

  queryVersionedVariables(env: string, applicationId: string, query: {versions: string[]}, paginationToken?: string): Promise<PaginatedList<ConfigurationVariable>> {
    const path = `/application/${applicationId}/variables/queryVersioned`;
    return this.sendRequest<PaginatedList<ConfigurationVariable>>(env, path, 'post', {
      queryStringParameters: {paginationToken},
      body: query
    });
  }

  async putVariable(env: string, variable: ConfigurationVariableInput): Promise<ConfigurationVariable> {
    const {applicationId, name} = variable;
    const path = `/application/${applicationId}/variable/${name}`;

    const response = await this.sendRequest<ConfigurationVariable>(env, path, 'put', {body: variable, queryStringParameters: this.baseEditingQueryStringParams});

    return response;
  }

  deleteVariable(env: string, applicationId: string, name: string): Promise<EmptySuccessResponse> {
    const path = `/application/${applicationId}/variable/${name}`;
    return this.sendRequest(env, path, 'delete', {queryStringParameters: this.baseEditingQueryStringParams});
  }

  rollbackVariable(env: WILDCLOUD_ENV, applicationId: string, name: string) : Promise<ConfigurationVariable> {
    const path = `/application/${applicationId}/variable/${name}/rollback`;
    return this.sendRequest<ConfigurationVariable>(env, path, 'post', {queryStringParameters: this.baseEditingQueryStringParams});
  }

  /********************************************
   *
   * Segments
   *
   ********************************************/

  listSegments(env: string, applicationId: string, paginationToken?: string): Promise<PaginatedList<ApplicationSegment>> {
    const path = `/applications/${applicationId}/segments`;
    return this.sendRequest<PaginatedList<ApplicationSegment>>(env, path, 'get', {
      queryStringParameters: {paginationToken}
    });
  }

  getSegment(env: string, applicationId: string, segmentId: string): Promise<ApplicationSegment> {
    const path = `/applications/${applicationId}/segments/${segmentId}`;
    return this.sendRequest(env, path, 'get');
  }

  updateSegment(env: string, applicationId: string, segmentId: string, segmentInput: ApplicationSegmentInput): Promise<ApplicationSegment> {
    const path = `/applications/${applicationId}/segments/${segmentId}`;
    return this.sendRequest(env, path, 'put', {body: segmentInput, queryStringParameters: this.baseEditingQueryStringParams});
  }

  createSegment(env: string, applicationId: string, segmentInput: ApplicationSegmentInput): Promise<ApplicationSegment> {
    const path = `/applications/${applicationId}/segments`;
    return this.sendRequest(env, path, 'post', {body: segmentInput, queryStringParameters: this.baseEditingQueryStringParams});
  }

  deleteSegment(env: string, applicationId: string, segmentId: string): Promise<EmptySuccessResponse> {
    const path = `/applications/${applicationId}/segments/${segmentId}`;
    return this.sendRequest(env, path, 'delete', {queryStringParameters: this.baseEditingQueryStringParams});
  }

  /********************************************
   *
   * Experiments
   *
   ********************************************/

  listExperiments(env: string, applicationId: string, paginationToken?: string): Promise<PaginatedList<Experiment>> {
    const path = `/application/${applicationId}/experiments`;
    return this.sendRequest<PaginatedList<Experiment>>(env, path, 'get', {
      queryStringParameters: {paginationToken}
    });
  }

  getExperiment(env: string, applicationId: string, experimentId: string): Promise<GetExperimentResponse> {
    const path = `/application/${applicationId}/experiment/${experimentId}`;
    return this.sendRequest<GetExperimentResponse>(env, path, 'get');
  }

  postExperiment(env: string, applicationId: string, experimentInput: ExperimentInput): Promise<Experiment> {
    const path = `/application/${applicationId}/experiments`;
    return this.sendRequest(env, path, 'post', {body: experimentInput, queryStringParameters: this.baseEditingQueryStringParams});
  }

  patchExperiment(env: string, applicationId: string, experimentId: string, patch: PatchExperimentRequest): Promise<Experiment> {
    const path = `/application/${applicationId}/experiment/${experimentId}`;
    return this.sendRequest(env, path, 'patch', {body: patch, queryStringParameters: this.baseEditingQueryStringParams});
  }

  deleteExperiment(env: string, applicationId: string, experimentId: string): Promise<EmptySuccessResponse> {
    const path = `/application/${applicationId}/experiment/${experimentId}`;
    return this.sendRequest(env, path, 'delete', {queryStringParameters: this.baseEditingQueryStringParams});
  }

  resolveExperiment(env: string, applicationId: string, experimentId: string, resolutionRequest: ResolveExperimentRequest): Promise<EmptySuccessResponse> {
    const path = `/application/${applicationId}/experiment/${experimentId}/resolve`;
    return this.sendRequest(env, path, 'post', {body: resolutionRequest, queryStringParameters: this.baseEditingQueryStringParams});
  }

  /********************************************
   *
   * Tournaments
   *
   *********************************************/

  async getTournament(env: string, applicationId: string, tournamentId: string) : Promise<TournamentDefinition> {
    throw new Error('Not implemented');
  }

  async listTournaments(env: string, applicationId: string) : Promise<TournamentDefinition[]> {
    const path = `/applications/${applicationId}/tournaments/definitions`;
    return this.sendRequest(env, path, 'get', {});
  }

  async updateTournament(env: string, applicationId: string, tournamentId: string, properties: any) : Promise<TournamentDefinition> {
    throw new Error('Not implemented');
  }

  async deleteTournament(env: string, applicationId: string, tournamentId: string) {
    throw new Error('Not implemented');
  }

  /********************************************
   *
   * Private
   *
   *********************************************/

  private async getApiName(env: string) {
    return `wildcloud_${env}`;
  }


  private async sendRequest<T>(env: string, path: string, method: HttpMethod, options?: RequestOptions): Promise<T> {
    if (!validateEnvironment(env)) {
      throw new Error(`Invalid argument env '${env}' for request ${method}:${path}`);
    }

    const handler = HandlerForHttpMethod[method];

    options = options ? Utilities.copy(options) : {};

    if (options.queryStringParameters) {
      for (const key of Object.keys(options.queryStringParameters)) {
        if (options.queryStringParameters[key] === undefined) {
          delete options.queryStringParameters[key];
        }
      }

      const keys = Object.keys(options.queryStringParameters);
      if (keys.length > 0) {
        const params = keys.map(key => `${key}=${options.queryStringParameters[key]}`);
        path = `${path}?${params.join('&')}`;
      }

      delete options.queryStringParameters;
    }

    let response: APIResponse<T>;

    const apiName =await this.getApiName(env);

    console.log(`Calling ${apiName}: ${method} ${path} with options:`, JSON.stringify(options));

    try {
      const result = await handler({
        apiName,
        path,
        options: {
          headers: {
            'Content-Type': 'application/json',
          },
          ...options
        }
      }).response;

      response = (await result.body.json()) as APIResponse<T>;
    } catch (error) {
      if (error.hasOwnProperty('response')) {
        response = error.response.data;
      } else {
        throw error;
      }
    }

    if (response.error) {
      console.error(JSON.stringify(response));
      throw new WildCloudError(response.error);
    }

    return response.result;
  }

  private get baseEditingQueryStringParams(): Record<string, string> {
    console.warn(this.authorizationService);
    console.warn(this.authorizationService.currentAuthenticatedUser);
    return {
      changeAuthor: this.authorizationService.currentAuthenticatedUser.userId
    };
  }
}

const HandlerForHttpMethod = {
  'get': API.get.bind(API),
  'post': API.post.bind(API),
  'put': API.put.bind(API),
  'patch': API.patch.bind(API),
  'delete': API.del.bind(API)
} as const;
type HttpMethod = keyof typeof HandlerForHttpMethod;
type RequestOptions = { body?: any, queryStringParameters?: Record<string, string> };
