import {QueryExecutionState} from "@aws-sdk/client-athena";

export const COMPLETED_QUERY_STATES : string[] = [
  QueryExecutionState.FAILED,
  QueryExecutionState.CANCELLED,
  QueryExecutionState.SUCCEEDED
];

export interface Record {
  [name: string]: any;
}

export type Records = Record[];

export interface AthenaQueryResult {
  data: AthenaQueryData;
}
export interface AthenaQueryData {
  header: string[];
  rows: string[][];
}

