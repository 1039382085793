import {Component, Input, OnInit} from '@angular/core';
import {DashboardService} from "../../../services/dashboard.service";
import {MatTableDataSource} from "@angular/material/table";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {
  CreateDashboardDialogComponent
} from "../create-dashboard-dialog/create-dashboard-dialog.component";
import {Router} from "@angular/router";
import {WildCloudEnvironmentName} from "../../../../../wildcard-dashboard-common/src/models/wildcloud";
import {WildCloudService} from "../../../wild-cloud/wild-cloud.service";
import {MoveDashboardDialogComponent} from "../move-dashboard-dialog/move-dashboard-dialog.component";
import {firstValueFrom} from 'rxjs';
import {AlertService} from "../../ui/alert.service";
import {AuthorizationService} from "../../../authorization.service";
import {ActivityViewDialog} from "../../ui/activity-view-dialog/activity-view-dialog.component";

@Component({
  selector: 'app-dashboard-list',
  templateUrl: './dashboard-list.component.html',
  styleUrls: ['./dashboard-list.component.css']
})
export class DashboardListComponent implements OnInit {
  @Input() domain: string;
  @Input() environment = '';

  get resolvedDomain(): string {
    let domain = this.domain;

    if ( this.environment === 'dev') {
      domain += '_dev';
    }

    return domain;
  }

  domainList = [];

  isReady = false;

  dashboards = [];
  displayedColumns = ['name'];

  constructor(private dashboardService: DashboardService,
              private matDialog: MatDialog,
              private router: Router,
              private alertService: AlertService,
              private authorizationService: AuthorizationService,
              private wildCloudService: WildCloudService) {

  }

  async ngOnInit() {
    await this.authorizationService.waitForAuthorization();
    await this.reload();
  }

  async reload() {
    if (!this) {
      return;
    }

    if (!this.domain) {
      return;
    }

    this.isReady = false;

    this.dashboards = await this.dashboardService.listDashboards(this.resolvedDomain);
    this.domainList = await this.dashboardService.getDomainList(this.environment);

    this.isReady = true;
  }

  getExistingDashboard(dashboardId: string): any {
    return this.dashboards.find(d => d.dashboardId === dashboardId);
  }

  async showCreateDialog(data: any) {
    const dialog = this.matDialog.open(CreateDashboardDialogComponent, {data});
    const result = await firstValueFrom(dialog.afterClosed());
    const domain = this.domain;

    if (result) {
      const {action} = result;

      if (action === 'create') {
        const {namespace, name, dashboardId} = result;
        const existingDashboard = this.getExistingDashboard(dashboardId);

        if (existingDashboard) {
          alert(`Dashboard with id ${dashboardId} already exists with name '${existingDashboard.name}'`);
          return;
        }

        await this.dashboardService.createDashboard({
          domain,
          namespace,
          name,
          dashboardId,
        });
      } else if (action === 'save') {
        const {namespace, name, dashboardId} = result;

        if (dashboardId !== data.dashboardId) {
          const existingDashboard = this.getExistingDashboard(dashboardId);

          if (existingDashboard) {
            alert(`Dashboard with id ${dashboardId} already exists with name '${existingDashboard.name}'`);
            return;
          }

          await this.dashboardService.deleteDashboard(data);
          await this.dashboardService.createDashboard({
            domain: this.domain,
            namespace,
            name,
            dashboardId,
          });
        } else {
          await this.dashboardService.updateDashboard({namespace, name, dashboardId, domain});
        }
      } else if (action === 'delete') {
        await this.dashboardService.deleteDashboard(data);
      }

      await this.reload();

      this.isReady = true;
    }
  }

  async viewActivity(dashboard) {
    const dialog = this.matDialog.open(ActivityViewDialog, {data: {url: this.router.url}});
    const result = await firstValueFrom(dialog.afterClosed());
  }

  async editDashboard(dashboard) {
    try {
      await this.showCreateDialog(dashboard);
    } catch (err) {
      alert(err);
    }
  }

  async addNewDashboard() {
    try {
      await this.showCreateDialog({
        namespace: 'default',
      });
    } catch (err) {
      console.error(err)
      await this.alertService.showError(err);
    }
  }

  openQuicksight() {
    const url = 'https://us-west-2.quicksight.aws.amazon.com/sn/start/analyses';
    window.open(url, '_blank');
  }

  async showDashboard(dashboard) {
    let url = this.router.url;

    console.warn('yo');
    console.warn(url);

    if (!url.endsWith('/dashboards')) {
      url += '/dashboards';
    }

    const dashboardUrl = `${url}/${dashboard.domain}/${dashboard.dashboardId}`;

    console.warn(`Redirecting to ${dashboardUrl}`);

    await this.router.navigate([dashboardUrl]);
  }

  async moveDashboard(dashboard) {
    const dialog = this.matDialog.open(MoveDashboardDialogComponent, {data: this.domainList});

    const result = await dialog.afterClosed().toPromise();

    if (result) {
      const {domain} = result;

      await this.dashboardService.moveDashboard( dashboard, domain );

      await this.reload();
    }
  }

  async refresh() {
    await this.reload();
  }
}
