import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {Application} from "../../../../../wildcard-dashboard-common/src/models/wildcloud";
import {GamePickerComponent, GamePickerDialogResult} from "../../ui/game-picker/game-picker.component";
import {firstValueFrom} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {DashboardUser} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'user-applications-editor',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, MatButtonModule],
  templateUrl: './user-applications-editor.component.html',
  styleUrls: ['./user-applications-editor.component.scss']
})
export class UserApplicationsEditorComponent {
  @Input() user: DashboardUser;
  @Input() games: Application[];

  @Input() selectedApplications: string[];
  @Output() selectedApplicationsChange = new EventEmitter<string[]>();

  constructor(private dialog: MatDialog) {}

  async manageApps() {
    const selectedIds = this.selectedApplications || this.games.map(g => g.id);

    const d = this.dialog.open(GamePickerComponent, {
      width: '600px',
      height: '500px',
      data: {
        user: this.user,
        games: this.games,
        selectedIds,
      }
    });

    const r : GamePickerDialogResult = await firstValueFrom(d.afterClosed());

    if (!r) {
      return;
    }

    this.selectedApplications = r.selectedIds;
    this.selectedApplicationsChange.emit(this.selectedApplications);
  }

  get isAllApps() {
    return this.selectedApplications.length === 0;
  }

  get selectedApps() : Application[] | undefined {
    return this.games
      .filter(game => this.selectedApplications.includes(game.id))
      .sort((a, b) => a.name.localeCompare(b.name));
  }
}
