import {Injectable} from '@angular/core';
import {ENVIRONMENT_DEV, ENVIRONMENT_PROD, WILDCLOUD_ENV, WildCloudService} from '../wild-cloud/wild-cloud.service';
import {Application, ModificationMetaData} from '../../../wildcard-dashboard-common/src/models/wildcloud';
import {AuthorizationService} from '../authorization.service';
import {ApplicationService} from '../application.service';

@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {

  constructor(private wildCloudService: WildCloudService,
              private authorizationService: AuthorizationService,
              private applicationService: ApplicationService) {
  }

  async listApplications(): Promise<Application[]> {
    const {items} = await this.wildCloudService.listApplications();

    return items.filter(app => this.authorizationService.hasApplicationAccess(app.id));
  }

  async getApplication(env: string, applicationId: string): Promise<Application> {
    return this.wildCloudService.getApplication(env, applicationId);
  }

  async updateApplication(env: WILDCLOUD_ENV, applicationId: string, properties: Partial<Application>) {
    return this.wildCloudService.updateApplication(env, applicationId, properties);
  }

  async createApplication(name: string, shortName: string, bundleIds: string[], platform: string) {
    const game: Omit<Application, 'id' | keyof ModificationMetaData> = {
      bundleIds,
      name,
      shortName,
      platform
    };

    await this.wildCloudService.createApplication(game);
  }

  async cloneApplication(id: string, name: string, shortName:string, bundleIds: string[]) {
    const app: Application = {id, name, shortName, bundleIds} as Application;
    await this.wildCloudService.cloneApplication(app);
  }

  async deleteApplication(applicationId: string, recurse: boolean = false) {
    await this.wildCloudService.deleteApplication(applicationId, recurse);
  }

  async saveAppIcon(game: Application, icon: File): Promise<string> {
    const iconName = `appIcon-${game.id}.png`;

    const newUrl = await this.applicationService.saveFile(icon, iconName);
    console.log(`newUrl: ${newUrl}`);

    if (!game.iconUrl || game.iconUrl !== newUrl) {
      await this.updateApplication(ENVIRONMENT_PROD, game.id, {iconUrl: newUrl});
      await this.updateApplication(ENVIRONMENT_DEV, game.id, {iconUrl: newUrl});
    }

    return newUrl;
  }
}
