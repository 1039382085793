import { Component, OnInit } from '@angular/core';
import {Access, AuthorizationService, Feature} from "../../authorization.service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, firstValueFrom} from "rxjs";
import {Application} from "../../../../wildcard-dashboard-common/src/models/wildcloud";
import {ApplicationsService} from "../../core/applications.service";

const gameRouteRegEx = new RegExp(/^\/games\/(\w+)\/([\w-]+)/);

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {

  game: Application;

  constructor(private authorizationService: AuthorizationService,
              private router: Router,
              private applicationsService: ApplicationsService,
              private activatedRoute: ActivatedRoute) { }

  async ngOnInit() {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.updateRoute().then();
    });

    await this.updateRoute();
  }

  get hasSettingsAccess(): boolean {
    return this.authorizationService.hasAccess(Feature.SiteSettings, Access.Read);
  }

  async updateRoute() {
    const {url} = this.router;

    // capture groups
    const match = gameRouteRegEx.exec(url);

    if (!match) {
      this.game = undefined;
      return;
    }

    this.game = await this.applicationsService.getApplication('live', match[2]);
  }

  get isAdminUser() {
    return this.authorizationService.isUserAdmin;
  }

  get isGameRoute() {
    return !!this.game;
  }

  get isDevDeployment() {
    const hostName = window.location.hostname;
    return hostName.includes('atlas-dev.wildcardstudios.com') || hostName.includes('localhost');
  }

  switchToProd() {
    let url = window.location.href;

    url = url.replace('atlas-dev.wildcardstudios.com', 'atlas.wildcardstudios.com');
    url = url.replace('localhost:4200', 'atlas.wildcardstudios.com');

    window.open(url, '_blank');
  }
}
