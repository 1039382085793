import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CommonModule } from '@angular/common';
import {AllDashboardRoleIds, DashboardRoleId} from "../../../../../wildcard-dashboard-common/src/models/dashboard-user";
import {UserApplicationsEditorComponent} from "../user-applications-editor/user-applications-editor.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {Application} from "../../../../../wildcard-dashboard-common/src/models/wildcloud";
import {FormsModule} from "@angular/forms";

const RoleToggleGroups = [
  [DashboardRoleId.Admin],
  [DashboardRoleId.Finance, DashboardRoleId.Growth, DashboardRoleId.Analyst],
  [DashboardRoleId.AppManager, DashboardRoleId.Developer, DashboardRoleId.QA, DashboardRoleId.CS],
];

const RoleToggleList = [
  DashboardRoleId.Admin,
  DashboardRoleId.Finance, DashboardRoleId.Growth, DashboardRoleId.Analyst,
  DashboardRoleId.AppManager, DashboardRoleId.Developer, DashboardRoleId.QA, DashboardRoleId.CS,
];

@Component({
  selector: 'user-roles-editor',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule, FormsModule],
  templateUrl: './user-roles-editor.component.html',
  styleUrls: ['./user-roles-editor.component.scss']
})
export class UserRolesEditorComponent implements OnInit{
  @Input() roles: DashboardRoleId[];
  @Output() rolesChange = new EventEmitter<DashboardRoleId[]>();

  toggleGroups = RoleToggleGroups;
  toggleList = RoleToggleList;

  enabledRoles: {[key: string]: boolean};

  ngOnInit(): void {
    this.enabledRoles = {};
    AllDashboardRoleIds.forEach(role => this.enabledRoles[role] = false);

    this.roles
      .filter(r => AllDashboardRoleIds.includes(r))
      .forEach(r => this.enabledRoles[r] = true);
  }
  isRoleEnabled(role: DashboardRoleId) {
    // console.log(role);
    if (this.enabledRoles[DashboardRoleId.Admin]) {
      return role === DashboardRoleId.Admin;
    }

    if (this.enabledRoles[DashboardRoleId.AppManager]) {
      return ![DashboardRoleId.QA, DashboardRoleId.CS, DashboardRoleId.Developer].includes(role);
    }

    return true;
  }

  isRoleSelected(role: DashboardRoleId): boolean {
    return this.enabledRoles[role];
  }

  getEnabledRoles() : DashboardRoleId[] {
    return Object.keys(this.enabledRoles)
      .sort()
      .filter(r => this.enabledRoles[r]) as DashboardRoleId[];
  }

  toggleRole(role: DashboardRoleId) {
    this.enabledRoles[role] = !this.enabledRoles[role];

    if (role === DashboardRoleId.Admin && this.enabledRoles[role]) {
      AllDashboardRoleIds.forEach(roleId => {
        if (roleId !== DashboardRoleId.Admin) {
          this.enabledRoles[roleId] = false;
        }
      });
    }

    if (role === DashboardRoleId.AppManager && this.enabledRoles[role]) {
      [DashboardRoleId.Developer, DashboardRoleId.CS, DashboardRoleId.QA].forEach(roleId => {
        this.enabledRoles[roleId] = false;
      });
    }

    this.roles = this.getEnabledRoles();
    this.rolesChange.emit(this.roles);
  }

}
